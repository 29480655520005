import { render, staticRenderFns } from "./Valongo.vue?vue&type=template&id=422c9615&scoped=true&"
import script from "./Valongo.vue?vue&type=script&lang=js&"
export * from "./Valongo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "422c9615",
  null
  
)

export default component.exports